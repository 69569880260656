import { useEffect } from 'react';

import { ErrorComponent } from '@wow/error';
import { pushAnalyticsEvent } from '../../utils/analytics';
import plainText from '../../lang/es.json';

const pushAnalyticsRetry = () => {
  pushAnalyticsEvent({
    event: 'select_content',
    content_group: 'Error - Contenido no encontrado',
    content_type: 'Pantalla de error',
    item_id: 'CTA - Ir al inicio',
  });
};

function ErrorPageContent() {
  useEffect(() => {
    pushAnalyticsEvent({
      event: 'trackContentGroup',
      content_group: 'Error - Contenido no encontrado',
    });
  }, []);

  return <ErrorComponent bodyText={plainText.errorPageContent.body} onRedirect={pushAnalyticsRetry} redirectTo='/' isRouter />;
}

export default ErrorPageContent;
