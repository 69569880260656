const spinnerStyles = {
  defaultProps: {
    size: 'xl',
  },
  baseStyle: {
    animationDuration: '0.8s',
    borderWidth: '4px',
    borderLeftColor: '#D6E2FF',
    borderBottomColor: 'currentColor',
    color: '#2172DC',
  },
};

export default spinnerStyles;
