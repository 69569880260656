import { useMemo } from 'react';

import { useToastOptions } from '../Auth/GlobalState';
import { getErrorMessageContent, ShowMessageFunctionArgs } from '../utils/errorHandling';

type ShowErrorMessageFunction = (args: ShowMessageFunctionArgs) => void;
type UseErrorMessageFunction = () => { showErrorMessage: ShowErrorMessageFunction };

const useErrorMessage: UseErrorMessageFunction = () => {
  const [, setToastOptions] = useToastOptions();

  const showErrorMessage: ShowErrorMessageFunction = useMemo(
    () => args => {
      const { title, description } = getErrorMessageContent(args);
      setToastOptions({ title, description, status: 'error' });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return { showErrorMessage };
};

export default useErrorMessage;
