import styled from 'styled-components';

const StyledPDFReader = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: fit-content;
  background-color: #d6e2ff;
  margin-bottom: 20px;

  .react-pdf__Page {
    border: solid 1px #c4c4c4;
  }

  .react-pdf__Page__svg {
    overflow: auto !important;
    width: 1000px !important;
    height: 450px !important;
    border-right: 6px solid white; // Add a right margin to the scrollbar

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #dae2f9;
      border-radius: 8px;
      margin: 20px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #565e71;
      border-radius: 8px;
    }
  }

  .pdf-reader-action-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(33, 114, 220);
    border-radius: 8px;
    height: 32px;
    width: 40px;

    &:hover {
      cursor: pointer;
    }

    &.disabled-button {
      background: #f2f0f4;

      &:hover {
        cursor: default;
      }
    }

    &.download-button {
      position: absolute;
      right: 0px;
    }
  }

  .pdf-reader {
    &__document {
      display: flex;
      justify-content: center;
      margin: 20px;
    }

    &__actions {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0px 10px 10px;
      position: relative;

      p {
        font-family: 'Inter';
        font-size: 14px;
      }

      &__navigation {
        display: flex;
        justify-content: center;
        align-items: center;

        & div {
          margin: 0px 10px;
        }
      }
    }
  }
`;

export default StyledPDFReader;
