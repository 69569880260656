import { createGalactic } from 'galactic-state';

export const [useSesionKey] = createGalactic({ auth: false });
export const [useShowBanner] = createGalactic(true);
export const [useToastOptions] = createGalactic({});
export const [useCriticalError] = createGalactic(null);
export const [usePlanName] = createGalactic<string>('');
export const [useShowRelatedToMyBalance] = createGalactic(true);
export const [useUserRole] = createGalactic<string>('');
export const [useHasNewPlan] = createGalactic(false);
