/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Button, ChakraProvider, extendTheme } from '@chakra-ui/react';
import { useEffect } from 'react';
import plainText from '../../lang/es.json';

import ThemeData from '../../Theme/theme';
import {
  ButtonConfig,
  ButtonLinkFooter,
  DropDown,
  Footer,
  HeaderDropDown,
  Icon,
  Line,
  SubtitleDropDown,
  TitlerDropDown,
  WrapperConfigColumns,
  WrapperDropDown,
  WrapperFooter,
  OptionCheckbox,
} from './styled';

interface ShowColumnsTableProps {
  getAllLeafColumns(): any;
  columnsDefaultTable(column: any): any;
  setShowPersonalizar(showPersonalizar: boolean): any;
  showPersonalizar: boolean;
  validateIsDefaultColumns(): any;
  resetDefaultColumns(): any;
  validateColumnsApplied(): any;
  applyChange(): any;
  icon: React.ReactNode;
}

const theme = extendTheme(ThemeData);

interface IBlanket {
  onClickBlanket(): void;
}

function Blanket(props: IBlanket) {
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions

    <div
      onClick={() => props.onClickBlanket()}
      data-testid='btn-dropdown'
      style={{
        bottom: 0,
        left: 0,
        top: 0,
        right: 0,
        position: 'fixed',
        zIndex: 0,
        backgroundColor: 'transparent',
      }}
    />
  );
}

function ShowColumnsTable(props: ShowColumnsTableProps) {
  const {
    showPersonalizar,
    getAllLeafColumns,
    icon,
    validateColumnsApplied,
    applyChange,
    columnsDefaultTable,
    setShowPersonalizar,
    validateIsDefaultColumns,
    resetDefaultColumns,
  } = props;

  useEffect(() => {
    if (showPersonalizar) {
      document.getElementById('pagination')?.classList.add('hide');
    } else {
      document.getElementById('pagination')?.classList.remove('hide');
    }
  }, [showPersonalizar]);

  useEffect(() => {
    const handleClick = () => {
      setShowPersonalizar(!showPersonalizar);
    };

    // Agregar el controlador de clic al elemento deseado
    if (showPersonalizar) {
      document.getElementById('header-id')?.addEventListener('click', handleClick);
    }

    // Limpia el controlador de clic cuando el componente se desmonta
    return () => {
      document.getElementById('header-id')?.removeEventListener('click', handleClick);
    };
    // we decided not add the useState set in the array of dependencies.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPersonalizar]);

  return (
    <ChakraProvider resetCSS={false} theme={theme}>
      {showPersonalizar ? <Blanket onClickBlanket={() => setShowPersonalizar(false)} /> : null}
      <WrapperConfigColumns>
        <ButtonConfig prop={showPersonalizar} onClick={() => setShowPersonalizar(!showPersonalizar)}>
          <Icon>{icon}</Icon>
        </ButtonConfig>

        <WrapperDropDown>
          <DropDown className={showPersonalizar ? 'show-dropdown' : 'hide'}>
            <HeaderDropDown>
              <TitlerDropDown>{plainText.showColumnsTable.title}</TitlerDropDown>
              <SubtitleDropDown>{plainText.showColumnsTable.subtitle}</SubtitleDropDown>
              <Line />
            </HeaderDropDown>
            <OptionCheckbox>{getAllLeafColumns().map((column: any) => columnsDefaultTable(column))}</OptionCheckbox>

            <WrapperFooter>
              <Line />
              <Footer>
                <ButtonLinkFooter disabled={validateIsDefaultColumns()} prop={validateIsDefaultColumns()} onClick={resetDefaultColumns}>
                  {plainText.button.reset}
                </ButtonLinkFooter>
                <Button size='md' disabled={validateColumnsApplied()} onClick={applyChange}>
                  {plainText.button.apply}
                </Button>
              </Footer>
            </WrapperFooter>
          </DropDown>
        </WrapperDropDown>
      </WrapperConfigColumns>
    </ChakraProvider>
  );
}

export default ShowColumnsTable;
