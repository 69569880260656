type Render = (() => JSX.Element) | JSX.Element;
type Status = 'error' | 'idle' | 'loading' | 'success';

interface RenderStatusProps {
  status: Status;
  renders: { [key in Status]?: Render };
}

export default function RenderStatus({ status, renders }: RenderStatusProps): JSX.Element | null {
  const render = renders[status];

  // If the render is a function () => JSX.Element
  if (typeof render === 'function') {
    return render();
  }

  // If the render is a JSX.Element
  if (render) {
    return render;
  }

  // If there is not a render
  return null;
}
