/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
import { StylesConfig } from 'react-select';
import styled from 'styled-components';

interface ButtonChip {
  disabled?: boolean;
  padding?: string;
}

const ButtonChip = styled.div<ButtonChip>`
  padding: ${props => props.padding || '0'};
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  height: 32px;
  border-radius: 8px;
  border-width: 1px;
  border-style: none;
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  background: ${props => (props.disabled ? '#F2F0F4' : '#d6e2ff')};
  z-index: 1;
  margin-left: 6px;

  &.chip-open-select {
    border-color: #d6e2ff;
    background: #d6e2ff;
  }

  &.chip-selected {
    border-color: rgb(185, 208, 249);
    background: #d6e2ff;
    button {
      color: #002e68;
      &:last-child {
        width: 32px;
        &:hover {
          transition: ease-in-out 0.2s;
          background: #d6e2ff;
          border-radius: 8px;
        }
      }
    }
  }

  button {
    height: 32px;
    border: none;
    background-color: transparent;
    line-height: 0;
    font-size: 14px;
    font-family: 'Inter';
    color: ${props => (props.disabled ? '#C7C6CA' : '#002e68')};
  }
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  justify-content: space-between;
  margin-inline: 4px 8px;
  span {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 700;
    color: #131c2c;
    margin-left: 12px;
  }
  button {
    border: none;
    outline: 0;
    background: transparent;
  }
`;

const selectStyles = (options: number) => {
  const selectCustomStyles: StylesConfig = {
    control: provided => ({
      ...provided,
      height: '40px',
      marginTop: '8px',
      paddingLeft: '8px',
      fontSize: '14px',
      flexDirection: 'row-reverse',
      display: options > 5 ? 'flex' : 'none',

      '.css-1hb7zxy-IndicatorsContainer': {
        display: 'none',
      },
    }),
    menu: () => ({ boxShadow: 'none' }),
    menuList: styles => {
      return {
        ...styles,
        maxHeight: '245px',
        paddingTop: '4px',
        paddingBottom: '4px',

        '::-webkit-scrollbar': {
          width: '4px',
        },

        '::-webkit-scrollbar-track': {
          background: '#DAE2F9',
          borderRadius: '4px',
          margin: '20px',
        },

        '::-webkit-scrollbar-thumb': {
          backgroundColor: '#565E71',
          borderRadius: '4px',
        },
      };
    },
    option: (styles, { isDisabled }) => {
      return {
        ...styles,
        paddingInline: '24px 16px',
        backgroundColor: 'transparent',
        color: '#2F3034',
        cursor: isDisabled ? 'not-allowed' : 'pointer',

        ':active': {
          ...styles[':active'],
          backgroundColor: 'transparent',
        },

        '.highlight': {
          fontWeight: 'bold',
          backgroundColor: 'transparent',
        },
        '[aria-disabled="true"] label': {
          cursor: 'not-allowed',
        },
        '[aria-disabled="true"] span': {
          cursor: 'not-allowed',
        },
        '[aria-disabled="true"] .customRadio[data-focus]': {
          boxShadow: 'none',
        },
        '[aria-disabled="true"] .optionLabel': {
          color: '#ACABAF',
        },
        '.fJUwDa .customRadio': {
          marginRight: '6px',
        },
        '.optionSelected': {
          color: 'var(--Grey-120, #2F3034)',
          fontWeight: 'normal',
        },
      };
    },
  };

  return selectCustomStyles;
};

const WrapOption = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  font-size: 14px;
  font-family: 'Inter';
  .customRadio {
    pointer-events: none;
    margin-right: 12px;
  }

  .optionSelected {
    font-weight: bold;
    color: #2172dc;
    font-family: 'Inter';
  }
  span.option-description {
    font-size: 12px;
    font-weight: 400;
    font-family: 'Inter';
  }
`;

const customToolTip = {
  borderRadius: '8px',
  color: '#F2F0F4',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '20px',
  backgroundColor: '#2F3034',
};

const MenuDropdownCustomSelect = styled.div`
  background-color: 'white';
  margin-top: '4px';
  position: 'absolute';
  z-index: 2;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
  width: '380px';
`;

export { ButtonChip, customToolTip, Label, MenuDropdownCustomSelect, selectStyles, WrapOption };
