type ShowErrorMessageFunction = (fn: () => void, wait: number) => () => void;

// eslint-disable-next-line import/prefer-default-export
export const throttle: ShowErrorMessageFunction = (fn, wait) => {
  let time = Date.now();
  return () => {
    if (time + wait - Date.now() < 0) {
      fn();
      time = Date.now();
    }
  };
};
