import { BadgeChakra } from '@wow/badge';
import { ButtonChakra } from '@wow/button';
import { RadioChakra } from '@wow/radio';
import Theme from '@chakra-ui/styled-system';

import Input from './components/inputStyles';
import Skeleton from './components/skeletonStyles';
import Spinner from './components/spinnerStyles';

const colors = {
  neutrals: {
    900: '#18191B',
    800: '#3B3E44',
    700: '#5F636D',
    600: '#737B8C',
    500: '#8A94A8',
    400: '#A4AEC3',
    300: '#BDC7DB',
    200: '#DEE3ED',
    100: '#F5F7FB',
    50: '#FAFAFA',
  },
  primary: {
    900: '#211240',
    800: '#2E195A',
    700: '#3C2074',
    600: '#49278E',
    500: '#5B31B2',
    400: '#7042CB',
    300: '#8862D4',
    200: '#B7A1E5',
    100: '#DBD0F2',
    50: '#F3EFFB',
  },
  accent: {
    700: '#0759C5',
    600: '#0759C5',
    500: '#2172DC',
    100: '#A6C7F2',
    50: '#D3E3F8',
  },
  error: {
    700: '#8D0C2B',
    600: '#BC1039',
    500: '#EF476F',
    100: '#F3A5B7',
    50: '#FDE3E9',
  },
  warning: {
    700: '#CC7E19',
    600: '#E9A54D',
    500: '#ECB165',
    100: '#F9D29F',
    50: '#FEF4E7',
  },
  success: {
    700: '#037C5D',
    600: '#05AE82',
    500: '#0DCF9D',
    100: '#9FE5BF',
    50: '#D9F2E2',
  },
};

const fonts = {
  heading: 'Red Hat Display, sans-serif',
  headingSemiBold: 'Red Hat Display Semibold, sans-serif',
  headingBold: 'Red Hat Display Bold, sans-serif',
  body: 'Red Hat Display',
  bodySemiBold: 'Inter Semibold, sans-serif',
  bodyBold: 'Inter Bold, sans-serif',
};

const textStyles = {
  h1: {
    fontFamily: 'headingBold',
    fontSize: '28px',
    lineHeight: '37px',
    fontWeight: 700,
  },
  h2: {
    fontFamily: 'headingSemiBold',
    fontSize: '22px',
    lineHeight: '29px',
  },
  h3: {
    fontFamily: 'headingBold',
    fontSize: '20px',
    lineHeight: '23px',
  },
  h4: {
    fontFamily: 'headingBold',
    fontSize: '18px',
    lineHeight: '21px',
  },
  h5: {
    fontFamily: 'headingBold',
    fontSize: '14px',
    lineHeight: '19px',
  },
  subtitle: {
    fontFamily: 'headingSemiBold',
    fontSize: '14px',
    lineHeight: '18px',
  },
  button: {
    fontFamily: 'headingBold',
    fontSize: '14px',
    lineHeight: '19px',
  },
  bradcrumbActive: {
    fontFamily: 'headingBold',
    fontSize: '14px',
    lineHeight: '19px',
  },
  bradcrumb: {
    fontFamily: 'heading',
    fontSize: '14px',
    lineHeight: '19px',
  },
  step: {
    fontFamily: 'headingBold',
    fontSize: '18px',
    lineHeight: '24px',
  },
  body: {
    fontFamily: 'body',
    fontSize: '12px',
    lineHeight: '17px',
  },
  bodyBold: {
    fontFamily: 'bodyBold',
    fontSize: '12px',
    lineHeight: '17px',
  },
  disclaimer: {
    fontFamily: 'bodySemiBold',
    fontSize: '14px',
    lineHeight: '17px',
  },
  label: {
    fontFamily: 'body',
    fontSize: '10px',
    lineHeight: '15px',
  },
  placeholder: {
    fontFamily: 'body',
    fontSize: '14px',
    lineHeight: '17px',
  },
  complete: {
    fontFamily: 'body',
    fontSize: '14px',
    lineHeight: '17px',
  },
  helper: {
    fontFamily: 'body',
    fontSize: '12px',
    lineHeight: '17px',
  },
};

const components = {
  Input,
  Spinner,
  Skeleton,
  Badge: BadgeChakra,
  Button: ButtonChakra,
  Radio: RadioChakra,
};

const theme: Theme.CustomThemeTypings = { components, colors, fonts, textStyles };

export default theme;
