import CSSReset from '@chakra-ui/css-reset';
import { IdProvider } from '@chakra-ui/hooks';
import { PortalManager } from '@chakra-ui/portal';
import { ChakraProviderProps, extendTheme } from '@chakra-ui/react';
import { EnvironmentProvider } from '@chakra-ui/react-env';
import { toCSSVar } from '@chakra-ui/styled-system';
import { ColorModeProvider, CSSVars, GlobalStyle } from '@chakra-ui/system';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import React, { useMemo } from 'react';
import { themeChakra } from '@wow/themes';
import { TooltipChakra } from '@wow/tooltip';
import { ButtonChakra } from '@wow/button';
import { SpinnerChakra } from '@wow/progress-indicator';

import ThemeData from '../Theme/theme';

export interface CustomChakraProviderProps extends ChakraProviderProps {
  addCSSVars?: boolean;
}

/**
 * The global provider that must be added to make all Chakra components
 * work correctly
 */
export function CustomChakraProvider({
  children,
  addCSSVars = false,
  colorModeManager,
  portalZIndex,
  resetCSS = false,
  environment,
  theme = extendTheme({
    ...ThemeData,
    components: {
      Tooltip: TooltipChakra,
      Button: ButtonChakra,
      Spinner: SpinnerChakra,
    },
    colors: {
      ...themeChakra.colors,
    },
  }),
  cssVarsRoot,
}: CustomChakraProviderProps): JSX.Element {
  const childrenWithEnvironment = <EnvironmentProvider environment={environment}>{children}</EnvironmentProvider>;
  const computedTheme = useMemo(() => toCSSVar(theme), [theme]);
  return (
    <IdProvider>
      <EmotionThemeProvider theme={computedTheme}>
        {addCSSVars && <CSSVars root={cssVarsRoot} />}
        <ColorModeProvider colorModeManager={colorModeManager} options={theme.config}>
          {resetCSS && <CSSReset />}
          {addCSSVars && <GlobalStyle />}
          {portalZIndex ? <PortalManager zIndex={portalZIndex}>{childrenWithEnvironment}</PortalManager> : childrenWithEnvironment}
        </ColorModeProvider>
      </EmotionThemeProvider>
    </IdProvider>
  );
}
