import styled from 'styled-components';
import plainText from '../lang/es.json';

export interface ProgressButtonProps {
  step: number;
  totalSteps: number;
  color: string;
  finishColor: string;
  text?: string;
  finalText?: string;
  onClick: () => void;
}

function ProgressButton({ step, totalSteps, color, finishColor, text, finalText, onClick }: ProgressButtonProps) {
  const isComplete = step === totalSteps;

  return (
    <Button onClick={onClick} disabled={!isComplete}>
      <ProgressBar progress={(step / totalSteps) * 100} color={color} completeColor={finishColor} />
      <span>
        {isComplete
          ? finalText ?? plainText.progressButton.finishSubscription
          : `${step}/${totalSteps} ${text ?? plainText.progressButton.totalSteps}`}
      </span>
    </Button>
  );
}

export default ProgressButton;

const Button = styled.button`
  background-color: #bababa;
  width: 243px;
  height: 48px;
  border-radius: 8px;
  border: none;
  padding: 0;
  position: relative;
  margin: 5px;
  cursor: pointer;

  &:disabled {
    cursor: auto;
  }

  span {
    color: white;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
`;

const ProgressBar = styled.div<{
  progress: number;
  color: string;
  completeColor: string;
}>`
  height: 100%;
  width: ${props => `${props.progress.toString()}%`};
  background-color: ${props => (props.progress === 100 ? props.completeColor : props.color)};
  border-radius: inherit;
  transition: width 1s ease-in-out;

  &:hover {
    opacity: ${props => (props.progress === 100 ? '0.7' : '1')};
  }

  &:acive {
    opacity: '1.2';
  }
`;
