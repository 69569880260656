/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-restricted-globals */
// eslint-disable-next-line import/no-unresolved

import plainText from '../../lang/es.json';
import { Container } from './styledUnActivatedToken';
import unActivatedTokenImage from '../../assets/img/key.png';
import OpenLink from '../OpenLink';

function UnActivatedToken() {
  return (
    <Container id='otp-container'>
      <div className='image'>
        {<img data-testid='screen-Err-img' src={unActivatedTokenImage} alt={plainText.unActivatedToken.altToken} id='otp-image' />}
      </div>
      <div className='title'>
        <p data-testid='screen-Err-title' id='otp-title'>
          {plainText.unActivatedToken.title}
        </p>
      </div>
      <div id='otp-main-description'>
        <div className='description' data-testid='screen-Err-desc-main'>
          {plainText.unActivatedToken.descriptionOne}
        </div>
        <div className='description' data-testid='screen-Err-desc-activation'>
          {plainText.unActivatedToken.descriptionTwo} <strong>{plainText.unActivatedToken.strongOne}</strong>
          {plainText.unActivatedToken.descriptionThree} <strong>{plainText.unActivatedToken.strongTwo}</strong>
          {plainText.unActivatedToken.descriptionFour} <strong>{plainText.unActivatedToken.strongThree}</strong>
        </div>
      </div>
      <div className='descriptionThree' data-testid='screen-Err-desc-download' id='otp-download-description'>
        {plainText.unActivatedToken.descriptionFive}
      </div>
      <div className='text-button' data-testid='screen-Err-button' id='otp-activate-link'>
        <OpenLink url={plainText.unActivatedToken.urlComplete} openMode={plainText.unActivatedToken.openMode}>
          <p>{plainText.unActivatedToken.textButton}</p>
        </OpenLink>
      </div>
    </Container>
  );
}

export default UnActivatedToken;
