import { ReactElement } from 'react';

import { hasSomePermission } from '../utils/permissions';
import ErrorPermissionContent from './ErrorPermissionContent';

interface WithPermissionsProps {
  children: ReactElement;
  permission: string[];
  showErrorPage?: boolean;
}

function WithPermissions({ children, permission, showErrorPage = false }: WithPermissionsProps): ReactElement | null {
  if (hasSomePermission(permission)) return children;

  return showErrorPage ? <ErrorPermissionContent /> : null;
}

export default WithPermissions;
