import { useEffect } from 'react';

import { ErrorComponent } from '@wow/error';
import { pushAnalyticsEvent } from '../../utils/analytics';
import plainText from '../../lang/es.json';

const pushAnalyticsRetryNew = () => {
  pushAnalyticsEvent({
    event: 'select_content',
    content_group: 'Error - Contenido protegido',
    content_type: 'Pantalla de error',
    item_id: 'CTA - Ir al inicio',
  });
};

function ErrorPermissionContent() {
  useEffect(() => {
    pushAnalyticsEvent({
      event: 'trackContentGroup',
      content_group: 'Error - Contenido protegido',
    });
  }, []);
  return (
    <ErrorComponent bodyText={plainText.errorPermissionContent.body} onRedirect={pushAnalyticsRetryNew} redirectTo='/' isRouter={false} />
  );
}

export default ErrorPermissionContent;
