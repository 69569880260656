const custom = () => ({
  field: {
    border: '1px solid',
    borderColor: 'var(--chakra-colors-neutrals-300)',
    borderRadius: '8px',
    bg: 'inherit',
    _disabled: {
      opacity: 1,
      cursor: 'default',
      bgColor: 'var(--chakra-colors-neutrals-200)',
      color: 'var(--chakra-colors-neutrals-500)',
    },
  },
});

export default {
  variants: { custom },
};
