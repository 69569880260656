import { Text } from '@chakra-ui/react';

export interface TextProps {
  text: string;
}

function TextComponent({ text }: TextProps) {
  return <Text color='var(--chakra-colors-error-700)'>{text}</Text>;
}

export default TextComponent;
