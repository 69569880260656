export const MAX_CONSECUTIVE_CHARACTERS = 3;

export const isAlphanumeric: (value: string) => boolean = value => /^[a-zA-Z0-9.,_\-"'`@]*$/.test(value);

export const reverse: (value: string) => string = value => value.split('').reverse().join('');
export const hasEqualsChars: (value: string) => boolean = value => {
  let result = false;
  for (let i = 0; i < value.length - 1 && !result; i += 1) {
    result = value[i] === value[i + 1];
  }
  return result;
};

export const hasConsecutiveChars: (
  value: string,
  checkReverse?: boolean,
  caseSensitive?: boolean,
  maxConsecutiveChars?: number,
) => boolean = (value, checkReverse, caseSensitive, maxConsecutiveChars = MAX_CONSECUTIVE_CHARACTERS) => {
  let valueReverse = '';
  let accumulator = 1;

  const newValue = caseSensitive ? value.toLocaleLowerCase() : value;

  if (!newValue || newValue.length < maxConsecutiveChars) return false;

  if (checkReverse) valueReverse = reverse(newValue);

  for (let i = 0; i <= newValue.length - 2 && accumulator <= maxConsecutiveChars; i += 1) {
    if (newValue.charCodeAt(i) + 1 === newValue.charCodeAt(i + 1) || valueReverse.charCodeAt(i) + 1 === valueReverse.charCodeAt(i + 1)) {
      accumulator += 1;
    } else {
      accumulator = 1;
    }
  }

  return accumulator > maxConsecutiveChars;
};
