import { Radio } from '@chakra-ui/react';
import { components, OptionProps } from 'react-select';

import { IOption } from '../../interfaces/IPropsCustomSelect';
import { WrapOption } from './Styled';

function Option(props: OptionProps) {
  const { isSelected, label, data } = props;
  const dataOption: IOption = data as IOption;

  return (
    // Esta advertencia se omite debido a que no encuentro solución por el momento de este caso,
    // ya que la misma documentación de react-select me lo presenta así <components.Option {...props}>

    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.Option {...props}>
      <WrapOption aria-disabled={dataOption.isDisabled}>
        <Radio size='lg' isChecked={isSelected} className='customRadio' disabled={dataOption.isDisabled}>
          {' '}
        </Radio>
        <div className={`${isSelected ? 'optionSelected' : ''}`} style={{ display: 'flex', flexDirection: 'column', fontSize: '14px' }}>
          <span className='optionLabel'>{label}</span>
          {dataOption.description !== undefined && <span className='option-description'>{dataOption.description}</span>}
        </div>
      </WrapOption>
    </components.Option>
  );
}

export default Option;
