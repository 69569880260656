import { Box } from '@chakra-ui/react';
import { ReactElement } from 'react';
import { BrowserRouter, Link } from 'react-router-dom';

import useErrorMessage from '../hooks/useErrorMessage';
import getHref from '../utils/openMode';
import plainText from '../lang/es.json';

export interface OpenLinkProps {
  children: ReactElement;
  url?: string;
  openMode?: string;
  onClick?: () => void;
  onError?: () => void;
  'data-testid'?: string;
  className?: string;
  reuseTab?: boolean;
}

function OpenLink({ children, openMode, url, onClick, onError, 'data-testid': dataTestId, className = '', reuseTab }: OpenLinkProps) {
  const { showErrorMessage } = useErrorMessage();

  const showError = () => {
    onClick && onClick();
    onError && onError();
    showErrorMessage({ title: plainText.openLink.error });
  };

  // Invalid option
  if (!url?.length || !openMode?.length) {
    return (
      <Box as='a' onClick={showError} tabIndex={0} cursor='pointer' data-openmode='invalid' data-testid={dataTestId} className={className}>
        {children}
      </Box>
    );
  }
  // Component option
  if (openMode === 'component') {
    const newURL = url.split('')[0] === '/' ? url : `/${url}`;
    return (
      <BrowserRouter>
        <Link
          to={newURL}
          className={`third-level-card-wrapper ${className}`}
          onClick={onClick}
          data-openmode={openMode}
          data-testid={dataTestId}
        >
          {children}
        </Link>
      </BrowserRouter>
    );
  }
  // Embedded option
  if (openMode === 'embedded') {
    return (
      <BrowserRouter>
        <Link
          to={`/open?src=${url}`}
          className={`third-level-card-wrapper ${className}`}
          onClick={onClick}
          data-openmode={openMode}
          data-testid={dataTestId}
        >
          {children}
        </Link>
      </BrowserRouter>
    );
  }

  // New tab or URL option
  const newTabProps = {
    'data-openmode': openMode,
    'data-testid': dataTestId,
    onClick,
    className: `third-level-card-wrapper ${className}`,
    href: getHref({
      module: openMode === 'sibApplication' || openMode === 'extApplication' ? openMode : 'urlComplete',
      urlComplete: url,
    }),
    target: reuseTab ? 'sib' : '_blank',
    rel: reuseTab ? undefined : 'noreferrer',
  };

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <a {...newTabProps}>{children}</a>;
}

export default OpenLink;
