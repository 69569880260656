import styled from 'styled-components';
import { FONT_TEXT_SCREEN_ERROR, FONT_TITLE_SCREEN_ERROR, FONT_NOMBRE_BANCO_DETAIL_SELECTED_ACCOUNT } from '../../designTokens/fonts';
import { COLOR_UNACTIVED_TOKEN, COLOR_UNACTIVED_TOKEN_BUTTON } from '../../designTokens/colors';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px;
  color: ${COLOR_UNACTIVED_TOKEN};

  .image {
    margin-bottom: 16px;
  }
  .title {
    font-family: ${FONT_TITLE_SCREEN_ERROR.fontFamily};
    font-weight: ${FONT_TITLE_SCREEN_ERROR.fontWeight};
    font-size: ${FONT_TITLE_SCREEN_ERROR.fontSize};
    line-height: 32px;
    margin-bottom: 8px;
    p {
      margin: 0;
    }
  }
  .description,
  .descriptionThree {
    font-family: ${FONT_TEXT_SCREEN_ERROR.fontFamily} !important;
    font-weight: ${FONT_TEXT_SCREEN_ERROR.fontWeight};
    font-size: ${FONT_TEXT_SCREEN_ERROR.fontSize};
    line-height: 22px;
  }
  .descriptionThree {
    margin-top: 25px;
  }
  .text-button {
    margin-top: 27px;
    height: 32px;
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 10px 12px;
    text-decoration-line: underline;
    font-weight: ${FONT_NOMBRE_BANCO_DETAIL_SELECTED_ACCOUNT.fontWeight};
    font-family: ${FONT_NOMBRE_BANCO_DETAIL_SELECTED_ACCOUNT.fontFamily};
    font-size: ${FONT_NOMBRE_BANCO_DETAIL_SELECTED_ACCOUNT.fontSize};
    color: ${COLOR_UNACTIVED_TOKEN_BUTTON};
    background-color: transparent;
    border: none;
    letter-spacing: 0.5px;
    cursor: pointer;

    a:visited {
      color: ${COLOR_UNACTIVED_TOKEN_BUTTON};
    }
  }
`;
