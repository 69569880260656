import { Button, Tooltip } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { FiChevronDown, FiX } from 'react-icons/fi';
import Select from 'react-select';
import plainText from '../../lang/es.json';

import { IOption, IPropsSelect } from '../../interfaces/IPropsCustomSelect';
import { CustomChakraProvider } from '../../providers/CustomChakraProvider';
import { Dropdown, DropdownIndicator } from './Dropdown';
import Option from './Option';
import { ButtonChip, Label, selectStyles } from './Styled';

export default function CustomSelect({
  options,
  onSelectOption,
  onClearFilter,
  value,
  label,
  ellipsis,
  tooltipText,
  name,
  disabled,
  padding,
}: IPropsSelect) {
  const [isOpen, setIsOpen] = useState(false);
  const [stateButton, setStateButton] = useState('');
  const optionLabel = useRef(null);

  const matchHightlight = (searchText: string) => {
    const regex = new RegExp(searchText, 'gi');
    document.querySelectorAll('.optionLabel').forEach(option => {
      const textOption = option.innerHTML.replace(/(<mark class="highlight">|<\/mark>)/gim, '');
      const newTextOption = textOption.replace(regex, '<mark class="highlight">$&</mark>');
      // eslint-disable-next-line no-param-reassign
      option.innerHTML = newTextOption;
    });
  };

  const setEllipsis = () => {
    let newLabel = value?.customLabel ? value?.description : value?.label;
    if (newLabel !== undefined && ellipsis !== undefined && ellipsis > 0) {
      newLabel = newLabel?.length > ellipsis ? newLabel?.slice(0, ellipsis).concat('...') : newLabel;
      newLabel = value?.customLabel ? `${value?.label} | ${newLabel}` : newLabel;
    }
    return newLabel;
  };

  useEffect(() => {
    const handleClick = () => {
      setIsOpen(!isOpen);
    };

    // Agregar el controlador de clic al elemento deseado
    if (isOpen) {
      document.getElementById('header-id')?.addEventListener('click', handleClick);
    }

    // Limpia el controlador de clic cuando el componente se desmonta
    return () => {
      document.getElementById('header-id')?.removeEventListener('click', handleClick);
    };
  }, [isOpen]);

  useEffect(() => {
    if (!value?.default && value !== undefined) {
      setStateButton('chip-selected');
    } else if (isOpen && value?.default) {
      setStateButton('chip-open-select');
    } else {
      setStateButton('');
    }
  }, [isOpen, value]);

  return (
    <CustomChakraProvider>
      <Tooltip hasArrow label={tooltipText} aria-label='A tooltip' placement='top'>
        <span>
          <Dropdown
            name={name || ''}
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            target={
              <ButtonChip id={name} className={stateButton} disabled={disabled} padding={padding}>
                <button data-testid='btn-chip' onClick={() => setIsOpen(prev => !prev)} disabled={disabled}>
                  {value ? `${setEllipsis()}` : plainText.button.select}
                </button>
                {value?.default ? (
                  <button data-testid='btn-chevrondown' onClick={() => setIsOpen(prev => !prev)} disabled={disabled}>
                    <FiChevronDown fontSize={18} />
                  </button>
                ) : (
                  <button onClick={() => setIsOpen(prev => !prev)} disabled={disabled}>
                    <FiChevronDown fontSize={18} />
                  </button>
                )}
              </ButtonChip>
            }
          >
            <Label>
              <span>{label}</span>
              <button data-testid='btn-fix' onClick={() => setIsOpen(false)}>
                <FiX fontSize={20} />
              </button>
            </Label>
            <div
              style={{ height: '1px', background: '#C7C6CA', marginBottom: '12px', left: '-12px', position: 'relative', width: '380px' }}
            />
            <Select
              ref={optionLabel}
              autoFocus
              backspaceRemovesValue={false}
              components={{ DropdownIndicator, IndicatorSeparator: null, Option }}
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              isClearable={false}
              menuIsOpen
              onInputChange={e => matchHightlight(e)}
              onChange={newValue => {
                onSelectOption(newValue as IOption);
                setIsOpen(false);
              }}
              options={options}
              placeholder={plainText.placeholder.search}
              styles={selectStyles(options.length)}
              tabSelectsValue={false}
              noOptionsMessage={() => plainText.customSelect.noResultsAlternative}
              value={value}
            />
            <div
              style={{ height: '1px', background: '#C7C6CA', margin: '12px 0px', left: '-12px', position: 'relative', width: '380px' }}
            />
            <div style={{ height: '48px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '12px' }}>
              <Button variant='primary-text' onClick={onClearFilter} isDisabled={value?.default && value !== undefined}>
                {plainText.button.delete}
              </Button>
            </div>
          </Dropdown>
        </span>
      </Tooltip>
    </CustomChakraProvider>
  );
}
