export const getArrayStorage = (arrayName: string) => {
  const item = sessionStorage.getItem(arrayName);
  if (item) {
    try {
      const value = JSON.parse(item);
      if (Array.isArray(value)) return value;
    } catch {
      return [];
    }
  }
  return [];
};

export const setArrayStorage = (arrayName: string, value: string[]) => {
  sessionStorage.setItem(arrayName, JSON.stringify(value));
};

export const clearArrayStorage = (arrayName: string) => {
  setArrayStorage(arrayName, []);
};

export const addArrayStorageItem = (arrayName: string, item: string) => {
  const array = getArrayStorage(arrayName);
  array.push(item);
  setArrayStorage(arrayName, array);
};

export const removeArrayStorageItem = (arrayName: string, item: string) => {
  const array = getArrayStorage(arrayName);
  const newArray = array.filter(element => element !== item);
  setArrayStorage(arrayName, newArray);
};
