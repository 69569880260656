import { IOpenMode } from '../interfaces/IOpenMode';

const pathSib = {
  transefencias: {
    confeccionar: 'menuRoot%3Dtef%26menuSelection%3Dtef%7Ctef.conf',
    confeccionar2: 'menuRoot=tef&menuSelection=tef|tef.conf',
  },
};

function OpenMode({ module, urlComplete }: IOpenMode) {
  let url = process.env.URL_BASE_SIB;
  const urlDomainSIB = process.env.URL_DOMAIN_SIB ? process.env.URL_DOMAIN_SIB : 'NO_URL_DOMAIN_SIB';
  if (url) {
    switch (module) {
      case 'confeccionarTransferencia':
        url += pathSib.transefencias.confeccionar;
        return url;
      case 'urlComplete':
        url += urlComplete;
        return url;
      case 'sibApplication':
        return urlDomainSIB + urlComplete;
      case 'extApplication':
        return urlComplete;
      default:
        return url;
    }
  }
}

export default OpenMode;
