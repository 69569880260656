import styled from 'styled-components';
import {
  BACKGROUND_BTN_CUSTOM_BUTTON,
  BACKGROUND_BTN_DISABLED_CUSTOM_BUTTON,
  COLOR_BTN_DISABLED_CUSTOM_BUTTON,
  BACKGROUND_DROPDOW_REPORTS,
  COLOR_SCROLLBAR_TRACK,
  COLOR_SCROLLBAR_THUMB,
  BACKGROUND_BTN_REPORTS_OPEN,
  COLOR_TITLE_DROPDOWN,
} from '../../designTokens/colors';

const WrapperConfigColumns = styled.div`
  margin: 20px 0px 20px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const WrapperDropDown = styled.div`
  div {
    position: relative;
    &.hide {
      display: none;
    }
    &.show-dropdown {
      right: 70px;
      margin-top: 33px;
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 0;
      width: 384px;
      height: 592px;
      background-color: ${BACKGROUND_DROPDOW_REPORTS};
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
      padding: 2px;
      gap: 4px;
    }
    &.custom-hover label {
      cursor: pointer;
    }
  }
`;

const OptionCheckbox = styled.div`
  width: 360px;
  height: 440px;
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: ${COLOR_SCROLLBAR_TRACK};
    border-radius: 8px;
    margin: 50px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${COLOR_SCROLLBAR_THUMB};
    border-radius: 8px;
  }
`;

interface Clicked {
  prop: boolean;
}

const ButtonConfig = styled.div<Clicked>`
  div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 40px;
    background: ${props => (props.prop ? BACKGROUND_BTN_REPORTS_OPEN : 'transparent')};
    border-radius: ${props => (props.prop ? '8px' : '0px')};
    flex: none;
    order: 1;
    flex-grow: 0;
  }
  :hover {
    background: ${BACKGROUND_BTN_REPORTS_OPEN};
    border-radius: 8px;
  }
`;

const Icon = styled.div`
  width: 20px;
  height: 20px;
  display: 'flex';
  flex-direction: 'row';
  justify-content: 'center';
  align-items: 'center';
`;

const DropDown = styled.div`
  height: auto;
  z-index: 0;
`;

const HeaderDropDown = styled.div`
  padding: 8px 8px 8px 8px;
  border-bottom: 1px ${COLOR_BTN_DISABLED_CUSTOM_BUTTON} !important;
`;

const TitlerDropDown = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  display: 'flex';
  align-items: 'center';
  letter-spacing: 0.1px;
  color: ${COLOR_TITLE_DROPDOWN};
`;

const SubtitleDropDown = styled.div`
  font-family: 'Inter';
  font-style: 'normal';
  font-size: 14px;
  line-height: 20px;
  display: 'flex';
  align-items: 'center';
  letter-spacing: 0.1px;
  color: ${COLOR_TITLE_DROPDOWN};
`;

const Separator = styled.div`
  width: 361px;
  height: 1px;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${COLOR_BTN_DISABLED_CUSTOM_BUTTON};
  margin-top: 6px;
`;

const WrapperFooter = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;
`;

const Footer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;

  .apply-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 12px;
    gap: 8px;
    width: 74px;
    height: 40px;
    background: ${BACKGROUND_BTN_CUSTOM_BUTTON};
    border-radius: 8px;
    flex: none;
    order: 2;
    flex-grow: 0;
    z-index: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: ${BACKGROUND_BTN_REPORTS_OPEN};
    border: none;
    cursor: pointer !important;
    transition-duration: none;

    &:disabled {
      background-color: ${BACKGROUND_BTN_DISABLED_CUSTOM_BUTTON};
      color: ${COLOR_BTN_DISABLED_CUSTOM_BUTTON};
      cursor: default;
      opacity: 1;

      &:hover {
        background-color: ${BACKGROUND_BTN_DISABLED_CUSTOM_BUTTON};
        color: ${COLOR_BTN_DISABLED_CUSTOM_BUTTON};
        cursor: default;
      }
    }

    &:hover {
      background-color: ${BACKGROUND_BTN_CUSTOM_BUTTON};
      color: ${BACKGROUND_BTN_REPORTS_OPEN};
      cursor: default;
    }
  }
`;

interface Validate {
  prop: boolean;
}

const ButtonLinkFooter = styled.button<Validate>`
  width: auto;
  height: 20px;
  border: none;
  text-decoration: underline;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  color: ${props => (props.prop ? COLOR_BTN_DISABLED_CUSTOM_BUTTON : BACKGROUND_BTN_CUSTOM_BUTTON)};
  background: transparent;
  font-style: normal;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.1px;
  cursor: ${props => (props.prop ? 'default' : 'pointer')};
`;

export {
  ButtonConfig,
  ButtonLinkFooter,
  DropDown,
  Footer,
  HeaderDropDown,
  Icon,
  Line,
  Separator,
  SubtitleDropDown,
  TitlerDropDown,
  WrapperConfigColumns,
  WrapperDropDown,
  WrapperFooter,
  OptionCheckbox,
};
