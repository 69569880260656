import crypto from 'crypto';
import { HmacSHA256, SHA256 } from 'crypto-js';
import { getArrayStorage, setArrayStorage } from './sessionArrayStorage';

type HasPermissionFunction = (permissionToCheck: string[], permissionList?: string[]) => boolean;

export const PERMISSIONS = 'permissions';

// Store all the permissions
export const setPermissions: (permissions: string[]) => void = permissions => {
  setArrayStorage(PERMISSIONS, permissions);
};

// Get all the permissions
export const getPermissions: () => string[] = () => getArrayStorage(PERMISSIONS);

// Check if has all the permissions
// Allow an string of comma separated permissions to check
export const hasAllPermission: HasPermissionFunction = (permissionToCheck, permissionList = getPermissions()) =>
  permissionToCheck.every(permission => {
    const splittedPermission = permission.replace(' ', '').split(',');
    if (splittedPermission.length > 1) {
      return hasAllPermission(splittedPermission, permissionList);
    }
    return permissionList.includes(splittedPermission[0]);
  });

// Check if has one of the the permissions
// Allow an string of comma separated permissions to check
export const hasSomePermission: HasPermissionFunction = (permissionToCheck, permissionList = getPermissions()) =>
  permissionToCheck.some(permission => hasAllPermission(permission.replace(' ', '').split(','), permissionList));

// Encrypt a string in sha256
export const hash = (value: string) => SHA256(value).toString();

// Encrypt a string in sha256 con clave
export const hashPass = (pass: string, secret: string) => HmacSHA256(secret, pass).toString();

// Password-based key derivation function
export const decrypt = (value: string, pass: string) => {
  // create a 32 byte key form the provided secret (need a fixed length key) using pbkdf2 derivation function.
  try {
    const key = crypto.pbkdf2Sync(pass, 'salt', 10, 32, 'sha256');
    // get IV from string
    const iv = Buffer.from(value.slice(value.length - 64, value.length - 32), 'hex');
    // get encrypted message from string
    const encryptedText = Buffer.from(value.slice(0, value.length - 64), 'hex');
    // Creating Decipher
    const decipher = crypto.createDecipheriv('aes-256-gcm', Buffer.from(key), iv);
    // Get auth tag and set it to decipher
    const authTag = Buffer.from(value.slice(value.length - 32), 'hex');
    decipher.setAuthTag(authTag);
    // Updating decrypted text
    let decrypted = decipher.update(encryptedText);
    decrypted = Buffer.concat([decrypted, decipher.final()]);

    // returns data after decryption
    return decrypted.toString();
  } catch {
    return null;
  }
};
