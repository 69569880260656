import { Alert, AlertDescription, AlertTitle, Box, CloseButton, Icon, Spacer, Text } from '@chakra-ui/react';
import { FiAlertCircle } from 'react-icons/fi';

import { CustomChakraProvider } from '../../providers/CustomChakraProvider';
import plainText from '../../lang/es.json';

interface ErrorBannerProps {
  onClose: () => void;
  onRetry: () => void;
  retryLabel?: string;
  title: string;
  subtitle?: string;
  show?: boolean;
}

export default function ErrorBanner({
  onClose,
  onRetry,
  retryLabel = plainText.button.retry,
  title,
  subtitle = plainText.renderStatusErrorBanner.defaultSubtitle,
  show = true,
}: ErrorBannerProps): JSX.Element | null {
  if (!show) {
    return null;
  }

  return (
    <CustomChakraProvider>
      <Box pr={10}>
        <Alert
          borderRadius='12px'
          bgColor='#FFDBE5'
          border='1px solid #E20028'
          boxShadow='0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)'
          mb={8}
          pr={3}
        >
          <Icon color='#E20028' as={FiAlertCircle} h={6} w={6} />
          <Box my={2} mx={4}>
            <AlertTitle>{title}</AlertTitle>
            {subtitle && <AlertDescription>{subtitle}</AlertDescription>}
          </Box>
          <Spacer />

          <Text
            mx={4}
            data-testid='btn_retry'
            textDecoration='underline'
            fontWeight={700}
            color='#E20028'
            onClick={onRetry}
            cursor='pointer'
          >
            {retryLabel}
          </Text>

          <CloseButton
            data-testid='btn_closeBanner'
            w='20px'
            h='20px'
            color='#E20028'
            border='none'
            bgColor='transparent'
            alignSelf='flex-start'
            onClick={onClose}
          />
        </Alert>
      </Box>
    </CustomChakraProvider>
  );
}
