import { Icon } from '@chakra-ui/react';
import React, { useState, useRef } from 'react';
import { FiChevronLeft, FiChevronRight, FiDownload } from 'react-icons/fi';

import StyledPDFReader from './StyledPDFReader';
import { CustomChakraProvider } from '../../providers/CustomChakraProvider';
import { throttle } from '../../utils/throttle';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const reactPdf = require('react-pdf/dist/esm/entry.webpack5');

const { Document, Page } = reactPdf;

interface PdfParams {
  data: Uint8Array;
}

type PDFFile = string | PdfParams;

interface PDFReaderProps {
  file: number[] | string;
  onFinishRead?: () => void;
}

function PDFReader({ file, onFinishRead }: PDFReaderProps) {
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState(1);

  const inputRef = useRef<HTMLElement>();

  // Generate the Uint8Array or the base64 string
  const pdfFile: PDFFile = typeof file === 'string' ? `data:application/pdf;base64,${file}` : { data: new Uint8Array(file) };

  // eslint-disable-next-line no-shadow
  const onDocumentLoadSuccess = ({ numPagesOnLoad }: { numPagesOnLoad: number }) => {
    setNumPages(numPagesOnLoad);
    setPageNumber(1);
  };

  const onRenderSuccess = () => {
    if (onFinishRead && numPages && pageNumber === numPages && inputRef?.current) {
      const throttledOnFinishRead = throttle(onFinishRead, 1000);

      const handleScroll = (event: Event): void => {
        const { scrollHeight, scrollTop, clientHeight } = event.target as HTMLInputElement;
        if (Math.abs(scrollHeight - clientHeight - scrollTop) < 200) {
          throttledOnFinishRead();
        }
      };
      // IF there is a onFinishRead callback function and the user is in the last page
      const scrollableZone = inputRef?.current?.firstElementChild;
      if (scrollableZone) {
        const { scrollHeight, clientHeight } = scrollableZone;

        // Call onFinishRead if there is no a scrollbar
        scrollHeight && clientHeight && scrollHeight <= clientHeight && onFinishRead();

        // Add the scroll handler with a throttle
        scrollableZone?.addEventListener('scroll', handleScroll);
      }
    }
  };

  const changePage = (offset: number) => setPageNumber(prevPageNumber => prevPageNumber + offset);

  const previousPage = () => changePage(-1);

  const nextPage = () => changePage(1);

  const downloadBlob = (blob: Blob) => {
    const fileURL = window.URL.createObjectURL(blob);
    // Setting various property values
    const alink = document.createElement('a');
    alink.href = fileURL;
    alink.download = 'DownloadedPDF.pdf';
    alink.click();
  };

  const onDownload = () => {
    if (typeof pdfFile === 'string') {
      fetch(pdfFile).then(response => {
        response.blob().then(downloadBlob);
      });
    } else {
      const blob = new Blob([pdfFile.data], { type: 'application/pdf' });
      downloadBlob(blob);
    }
  };

  return (
    <CustomChakraProvider>
      <StyledPDFReader className='pdf-reader'>
        <Document renderMode='svg' className='pdf-reader__document' file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
          <Page
            className='pdf-reader__document__page'
            scale={1.6}
            pageNumber={pageNumber}
            renderAnnotationLayer={false}
            renderTextLayer={false}
            inputRef={inputRef}
            onRenderSuccess={onRenderSuccess}
          />
        </Document>

        <div className='pdf-reader__actions'>
          <div className='pdf-reader__actions__navigation'>
            <div
              className={`pdf-reader-action-button ${pageNumber <= 1 ? 'disabled-button' : ''}`}
              onClick={() => (pageNumber <= 1 ? null : previousPage())}
              aria-hidden='true'
            >
              <Icon as={FiChevronLeft} w={8} h={6} color={pageNumber <= 1 ? '#C7C6CA' : '#FFF'} />
            </div>

            <p>
              {pageNumber || (numPages ? 1 : '--')} / {numPages || '--'}
            </p>

            <div
              className={`pdf-reader-action-button ${numPages && pageNumber >= numPages ? 'disabled-button' : ''}`}
              onClick={() => (numPages && pageNumber >= numPages ? null : nextPage())}
              aria-hidden='true'
            >
              <Icon as={FiChevronRight} w={8} h={6} color={numPages && pageNumber >= numPages ? '#C7C6CA' : '#FFF'} />
            </div>
          </div>

          <div className='pdf-reader-action-button download-button' onClick={onDownload} aria-hidden='true'>
            <Icon as={FiDownload} w={8} h={6} color='#FFF' />
          </div>
        </div>
      </StyledPDFReader>
    </CustomChakraProvider>
  );
}

export default PDFReader;
