import { useSesionKey } from './GlobalState';

interface PrivateRenderProps {
  children: JSX.Element | JSX.Element[];
}

export function PrivateRender({ children }: PrivateRenderProps): JSX.Element {
  const [sessionKey] = useSesionKey();

  if (!sessionKey.auth) {
    return <div />;
  }

  return <> {children}</>;
}

export function getOidcStoraged() {
  const getOidcStorage =
    sessionStorage.getItem(`oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`) || '';
  return getOidcStorage ? JSON.parse(getOidcStorage) : undefined;
}

export function getToken() {
  const getOidcStorage =
    sessionStorage.getItem(`oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`) || '';
  const oidcStorage = getOidcStorage !== '' ? JSON.parse(getOidcStorage) : undefined;
  if (oidcStorage) {
    return oidcStorage.access_token;
  }
  return undefined;
}
