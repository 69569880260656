export interface ShowMessageFunctionArgs {
  error?: unknown;
  title?: string;
  description?: string;
}
type GetErrorMessageContentFunction = (args: ShowMessageFunctionArgs) => Omit<ShowMessageFunctionArgs, 'error'>;

const DEFAULT_ERROR_MESSAGE = 'No podemos responder tu solicitud. Recargá la página o volvé a intentar más tarde.';

// Get the error message
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getErrorDescription = (error: any) => error?.response?.data?.data?.error || undefined;

// get the error status code
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getErrorCode = (error: any) => error?.response?.status;

// true if the code is a 403 Forbidden or a 401 Unauthorized
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isForbiddenError = (error: any) => [401, 403].includes(getErrorCode(error));

// get the content for the error toast
export const getErrorMessageContent: GetErrorMessageContentFunction = ({ error, title, description } = {}) => {
  const errorCode = getErrorCode(error);
  const errorMessageTitle = title;
  let errorMessageDescription = description;

  // Return the default error message if the error code is 5XX
  if (error && (!errorCode || errorCode >= 500)) {
    return { description: errorMessageTitle ?? DEFAULT_ERROR_MESSAGE };
  }

  // Try to get the error message if there is not a custom error description
  if (!errorMessageDescription) {
    errorMessageDescription = getErrorDescription(error);
  }

  // Return the default error message if there are not description and title
  if (!errorMessageDescription && !errorMessageTitle) {
    return { description: DEFAULT_ERROR_MESSAGE };
  }

  // Render title as description when there is not a description
  if (!errorMessageDescription && errorMessageTitle) {
    return { description: errorMessageTitle };
  }

  // Return the created error message
  return { description: errorMessageDescription, title: errorMessageTitle };
};
