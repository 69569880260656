/* eslint-disable simple-import-sort/imports */
import { Global } from '@emotion/react';

// Imports font Inter
import interEmbebed from '../assets/fonts/inter-v8-latin-regular.eot';
import interWoff2 from '../assets/fonts/inter-v8-latin-regular.woff2';
import interWoff from '../assets/fonts/inter-v8-latin-regular.woff';
import interTtf from '../assets/fonts/inter-v8-latin-regular.ttf';
import interSvg from '../assets/fonts/inter-v8-latin-regular.svg';

// Imports font Inter Semi Bold
import interSemiBoldEmbebed from '../assets/fonts/inter-v8-latin-500.eot';
import interSemiBoldWoff2 from '../assets/fonts/inter-v8-latin-500.woff2';
import interSemiBoldWoff from '../assets/fonts/inter-v8-latin-500.woff';
import interSemiBoldTtf from '../assets/fonts/inter-v8-latin-500.ttf';
import interSemiBoldSvg from '../assets/fonts/inter-v8-latin-500.svg';

// Imports font Bold
import interBoldEmbebed from '../assets/fonts/inter-v8-latin-700.eot';
import interBoldWoff2 from '../assets/fonts/inter-v8-latin-700.woff2';
import interBoldWoff from '../assets/fonts/inter-v8-latin-700.woff';
import interBoldTtf from '../assets/fonts/inter-v8-latin-700.ttf';
import interBoldSvg from '../assets/fonts/inter-v8-latin-700.svg';

// Import font Red Hat display
import RedHatEmbebed from '../assets/fonts/red-hat-display-v11-latin-regular.eot';
import RedHatWoff2 from '../assets/fonts/red-hat-display-v11-latin-regular.woff2';
import RedHatWoff from '../assets/fonts/red-hat-display-v11-latin-regular.woff';
import RedHatTtf from '../assets/fonts/red-hat-display-v11-latin-regular.ttf';
import RedHatSvg from '../assets/fonts/red-hat-display-v11-latin-regular.svg';

// Import font Red Hat display Semi Bold
import RedHatSemiBoldEmbebed from '../assets/fonts/red-hat-display-v11-latin-500.eot';
import RedHatSemiBoldWoff2 from '../assets/fonts/red-hat-display-v11-latin-500.woff2';
import RedHatSemiBoldWoff from '../assets/fonts/red-hat-display-v11-latin-500.woff';
import RedHatSemiBoldTtf from '../assets/fonts/red-hat-display-v11-latin-500.ttf';
import RedHatSemiSvg from '../assets/fonts/red-hat-display-v11-latin-500.svg';

// Import font Red Hat display Bold
import RedHatBoldEmbebed from '../assets/fonts/red-hat-display-v11-latin-700.eot';
import RedHatBoldWoff2 from '../assets/fonts/red-hat-display-v11-latin-700.woff2';
import RedHatBoldWoff from '../assets/fonts/red-hat-display-v11-latin-700.woff';
import RedHatBoldTtf from '../assets/fonts/red-hat-display-v11-latin-700.ttf';
import RedHatBoldSvg from '../assets/fonts/red-hat-display-v11-latin-700.svg';

function Fonts(): JSX.Element {
  return (
    <Global
      styles={`
        @font-face {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            src: url(${interEmbebed}); /* IE9 Compat Modes */
            src: local(''),
                url(${interEmbebed}) format('embedded-opentype'), /* IE6-IE8 */
                url(${interWoff2}) format('woff2'), /* Super Modern Browsers */
                url(${interWoff}) format('woff'), /* Modern Browsers */
                url(${interTtf}) format('truetype'), /* Safari, Android, iOS */
                url(${interSvg}) format('svg'); /* Legacy iOS */
        }
        @font-face {
            font-family: 'Inter Semibold';
            font-style: normal;
            font-weight: 500;
            src: url(${interSemiBoldEmbebed}); /* IE9 Compat Modes */
            src: local(''),
                url(${interSemiBoldEmbebed}) format('embedded-opentype'), /* IE6-IE8 */
                url(${interSemiBoldWoff2}) format('woff2'), /* Super Modern Browsers */
                url(${interSemiBoldWoff}) format('woff'), /* Modern Browsers */
                url(${interSemiBoldTtf}) format('truetype'), /* Safari, Android, iOS */
                url(${interSemiBoldSvg}) format('svg'); /* Legacy iOS */
        }
        @font-face {
            font-family: 'Inter Bold';
            font-style: normal;
            font-weight: 700;
            src: url(${interBoldEmbebed}); /* IE9 Compat Modes */
            src: local(''),
                url(${interBoldEmbebed}) format('embedded-opentype'), /* IE6-IE8 */
                url(${interBoldWoff2}) format('woff2'), /* Super Modern Browsers */
                url(${interBoldWoff}) format('woff'), /* Modern Browsers */
                url(${interBoldTtf}) format('truetype'), /* Safari, Android, iOS */
                url(${interBoldSvg}) format('svg'); /* Legacy iOS */
            }
        @font-face {
            font-family: 'Red Hat Display';
            font-style: normal;
            font-weight: 400;
            src: url(${RedHatEmbebed}); /* IE9 Compat Modes */
            src: local(''),
                url(${RedHatEmbebed}) format('embedded-opentype'), /* IE6-IE8 */
                url(${RedHatWoff2}) format('woff2'), /* Super Modern Browsers */
                url(${RedHatWoff}) format('woff'), /* Modern Browsers */
                url(${RedHatTtf}) format('truetype'), /* Safari, Android, iOS */
                url(${RedHatSvg}) format('svg'); /* Legacy iOS */
        }
        @font-face {
            font-family: 'Red Hat Display Semibold';
            font-style: normal;
            font-weight: 500;
            src: url(${RedHatSemiBoldEmbebed}); /* IE9 Compat Modes */
            src: local(''),
                url(${RedHatSemiBoldEmbebed}) format('embedded-opentype'), /* IE6-IE8 */
                url(${RedHatSemiBoldWoff2}) format('woff2'), /* Super Modern Browsers */
                url(${RedHatSemiBoldWoff}) format('woff'), /* Modern Browsers */
                url(${RedHatSemiBoldTtf}) format('truetype'), /* Safari, Android, iOS */
                url(${RedHatSemiSvg}) format('svg'); /* Legacy iOS */
        }
        @font-face {
            font-family: 'Red Hat Display Bold';
            font-style: normal;
            font-weight: 700;
            src: url(${RedHatBoldEmbebed}); /* IE9 Compat Modes */
            src: local(''),
                url(${RedHatBoldEmbebed}) format('embedded-opentype'), /* IE6-IE8 */
                url(${RedHatBoldWoff2}) format('woff2'), /* Super Modern Browsers */
                url(${RedHatBoldWoff}) format('woff'), /* Modern Browsers */
                url(${RedHatBoldTtf}) format('truetype'), /* Safari, Android, iOS */
                url(${RedHatBoldSvg}) format('svg'); /* Legacy iOS */
        }
    `}
    />
  );
}

export default Fonts;
