// Hook usado para almacenar modificaciones (agregar y quitar) a una lista de elementos

import { useRef } from 'react';

type useModificationsStorageType = <T>() => {
  aggregated: T[];
  removed: T[];
  handleModification: (add: boolean, item: T) => void;
  add: (item: T) => void;
  remove: (item: T) => void;
};

const useModificationsStorage: useModificationsStorageType = <T,>() => {
  const itemsAggregated = useRef<T[]>([]);
  const itemsRemoved = useRef<T[]>([]);

  // Maneja el agregado o eliminado de un elemento
  const handleModification = (addItem: boolean, item: T) => {
    let listToPush = itemsAggregated.current;
    let listToTake = itemsRemoved.current;
    if (!addItem) {
      listToTake = itemsAggregated.current;
      listToPush = itemsRemoved.current;
    }
    // Si el elemento esta en la lista contraparte se elimina
    const index = listToTake.indexOf(item);
    if (index >= 0) {
      listToTake.splice(index, 1);
    } else {
      // Si no esta en la lista contraparte, se agrega a la lista actual
      listToPush.push(item);
    }
  };

  // Agrega un elemento a la lista de items agregados
  // Si el item esta en la lista de eliminados se quita y no se agrega en la lista de agregados
  const add = (item: T) => handleModification(true, item);

  // Agrega un elemento a la lista de items eliminados
  // Si el item esta en la lista de agregados se quita y no se agrega en la lista de eliminados
  const remove = (item: T) => handleModification(false, item);

  return {
    aggregated: itemsAggregated.current,
    removed: itemsRemoved.current,
    handleModification,
    add,
    remove,
  };
};

export default useModificationsStorage;
