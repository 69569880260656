import { DependencyList, useEffect } from 'react';

type UseScrollToFunction = (options: ScrollToOptions, deps?: DependencyList) => void;

const useScrollTo: UseScrollToFunction = ({ left, top, behavior }, deps) => {
  useEffect(() => {
    document.documentElement.scrollTo({ left, top, behavior });
  }, [left, top, behavior, deps]);
};

export default useScrollTo;
