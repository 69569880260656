const skeletonStyles = {
  defaultProps: {
    startColor: '#E3E2E6',
    endColor: '#FDFBFF',
  },
  baseStyle: {
    borderRadius: '12px',
  },
};

export default skeletonStyles;
