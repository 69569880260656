import OpenMode from './openMode';

export interface ReuseSibTabProps {
  urlToOpen: string;
}

function reuseSibTab({ urlToOpen }: ReuseSibTabProps) {
  const parsedUrlToOpen = OpenMode({
    module: 'urlComplete',
    urlComplete: urlToOpen,
  });

  window.open(parsedUrlToOpen, 'sib');
}

export default reuseSibTab;
